import * as React from 'react';
import RcCollapse from 'rc-collapse';
import classNames from 'classnames';
import RightOutlined from '@ant-design/icons/RightOutlined';
import CollapsePanel from './CollapsePanel';
import { ConfigContext } from '../config-provider';
import animation from '../_util/openAnimation';
import { cloneElement } from '../_util/reactNode';
const Collapse = props => {
    const { getPrefixCls, direction } = React.useContext(ConfigContext);
    const { prefixCls: customizePrefixCls, className = '', bordered, ghost } = props;
    const prefixCls = getPrefixCls('collapse', customizePrefixCls);
    const getIconPosition = () => {
        const { expandIconPosition } = props;
        if (expandIconPosition !== undefined) {
            return expandIconPosition;
        }
        return direction === 'rtl' ? 'right' : 'left';
    };
    const renderExpandIcon = (panelProps = {}) => {
        const { expandIcon } = props;
        const icon = (expandIcon ? (expandIcon(panelProps)) : (<RightOutlined rotate={panelProps.isActive ? 90 : undefined}/>));
        return cloneElement(icon, () => ({
            className: classNames(icon.props.className, `${prefixCls}-arrow`),
        }));
    };
    const iconPosition = getIconPosition();
    const collapseClassName = classNames({
        [`${prefixCls}-borderless`]: !bordered,
        [`${prefixCls}-icon-position-${iconPosition}`]: true,
        [`${prefixCls}-rtl`]: direction === 'rtl',
        [`${prefixCls}-ghost`]: !!ghost,
    }, className);
    const openAnimation = Object.assign(Object.assign({}, animation), { appear() { } });
    return (<RcCollapse openAnimation={openAnimation} {...props} expandIcon={(panelProps) => renderExpandIcon(panelProps)} prefixCls={prefixCls} className={collapseClassName}/>);
};
Collapse.Panel = CollapsePanel;
Collapse.defaultProps = {
    bordered: true,
};
export default Collapse;
